import axios from 'axios'
import api from '@/config/api.js'
import externalComponent from '@/utils/external-component.js'
import { EventBus } from '@/assets/static/event-bus.js'

import Logo from '@/components/icons/Logo'
const FormInput = () => externalComponent('formInput')
const FormButton = () => externalComponent('formButton')

export default {
  name: 'Login',

  components: {
    Logo,
    FormInput,
    FormButton
  },

  data() {
    return {
      user: {
        email: '',
        password: ''
      },

      error: {
        email: false,
        password: false
      }
    }
  },

  methods: {
    validateFields() {
      this.error.email = (this.user.email === '')
        ? true
        : false;

      this.error.password = (this.user.password === '')
        ? true
        : false;

      if (this.user.email === '' || this.user.password === '') {
        this.$toast.warning('Hay campo(s) vacío(s)');
      }

      return (!this.error.email && !this.error.password)
        ? true
        : false;
    },

    emailError() {
      this.error.email = true;
      this.$toast.warning('El correo electrónico no existe');
    },

    passwordError() {
      this.error.password = true;
      this.$toast.warning('La contraseña no coincide');
    },

    queryError() {
      this.$toast.error('Error en la consulta');
    },

    levelError() {
      this.$toast.error('Error al consultar el nivel de usuario');
    },

    async getUserLevel(id) {
      let response;
      let error = false;

      return response.data;
    },

    async getCredentials(user) {

      this.$session.start();

      this.$session.set('id', user._id);
      this.$session.set('name', user.name);
      this.$session.set('role', user.role);
    },

    async login() {
      if (this.validateFields()) {
        let response;
        let error = false;

        await axios
          .post(api + '/admin/login', this.user)
          .then(res => response = res)
          .catch(err => error = true);
        
        if (!error) {
          if (response.status === 200) {
            // console.log(response);
            await this.getCredentials(response.data);
            // console.log(this.$session.getAll());
            // console.log('dashboard...');
            // this.$router.push('/dashboard');
            this.$router.push('/calendar');
          } else if (response.status === 202) {
            this.emailError();
          } else if (response.status === 204) {
            this.passwordError();
          }
        } else {
          this.queryError();
        }
      }
    },

    inputFocus( time = null ) {
      if (time === null)
        time = 1000;
      setTimeout(()=>{
        try {
          this.$refs.email.$el.children[1].children[0].focus();
        } catch (error) {}
      }, time)
    }
  },

  mounted: function() {
    this.inputFocus();
  },

  beforeCreate: function() {
    if (this.$session.exists()) {
      this.$router.push('/dashboard');
    }
  }
}
